import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import Card from '../../Card';
import { device } from '../../../constants';
import EventCategoriesContext from '../../../context/EventCategoriesContext';
import { keyBy } from 'lodash';
import generateSlug from '../../../util/generateSlug';

const Container = styled.div`
  margin-right: 0;
  margin-bottom: ${props => props.theme.paddings.halfpd}px;
  flex-basis: 50%;

  & > div {
    margin-bottom: ${props => props.theme.paddings.halfpd}px;
  }

  @media screen and ${device.laptop} {
    margin-bottom: 0;
    margin-right: calc(${props => props.theme.paddings.halfpd}px / 2);

    & > div:last-child {
      margin-bottom: 0;
    }
  }
`;

interface Props {
  events: App.Event[];
}
const HomepageEventsFGComponent: React.FC<Props> = function (props) {
  const { events } = props;
  const eventCategories = useContext(EventCategoriesContext) ?? [];
  const eventCategoriesKeyed = useMemo(() => keyBy(eventCategories, 'name'), [
    eventCategories,
  ]);

  return (
    <Container>
      {events.length > 0 ? (
        events.map((e, index: number) => {
          const featuredImgSrc =
            e.featured_image !== ''
              ? e.featured_image
              : '/images/design/hero/resources.png';

          return (
            <Card
              title={e.title}
              subtitle={
                eventCategoriesKeyed[e.categories[0]]?.label ??
                e.categories[0] ??
                'Upcoming Event'
              }
              key={index}
              featuredImgSrc={featuredImgSrc}
              link={`/event/${generateSlug(e.title)}`}
            >
              {e.excerpt}
            </Card>
          );
        })
      ) : (
        <Card title="" subtitle="Upcoming Event">
          No events yet.
        </Card>
      )}
    </Container>
  );
};

export default HomepageEventsFGComponent;
