import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import Card from '../../Card';
import SectionDescription from '../../formatting/SectionDescription';
import moment from 'moment';
import EventCategoriesContext from '../../../context/EventCategoriesContext';
import { keyBy } from 'lodash';
import generateSlug from '../../../util/generateSlug';

const Container = styled.section`
  min-height: 280px;
  flex-basis: 50%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  & > div:first-child {
    border-right: 0px;
  }
  & > div:last-child {
    grid-column: 1/3;
    border-top: 0px;
  }
`;

const DateOfMonth = styled.span`
  font-family: 'Open Sans';
  font-size: 140px;
  font-weight: 600;
`;

interface Props {
  event?: App.Event;
}
const HomepageEventsCETComponent: React.FC<Props> = function (props) {
  const { event } = props;

  const eventCategories = useContext(EventCategoriesContext) ?? [];
  const eventCategoriesKeyed = useMemo(() => keyBy(eventCategories, 'name'), [
    eventCategories,
  ]);

  if (event == null) {
    return null;
  }

  const { start_date } = event.date_ranges?.[0];

  const monthDateYear = start_date
    ? moment(start_date).format('MMMM DD, YYYY')
    : 'No date set yet.';
  const month = start_date
    ? moment(start_date).format('MMM').toUpperCase()
    : 'No date set yet.';
  const date = start_date ? moment(start_date).format('DD') : '';
  const time = start_date ? moment(start_date).format('ha').toUpperCase() : '';

  return (
    <Container>
      <Card>
        <SectionDescription>{month}</SectionDescription>
        <DateOfMonth>{date}</DateOfMonth>
      </Card>
      <Card className="align-items-center">
        {monthDateYear}
        <br />
        {time}
        <br />
        {event.address}
        <br />
      </Card>
      <Card
        title={event.title}
        subtitle={
          event.categories
            ? eventCategoriesKeyed[event.categories[0]]?.label ??
              event.categories[0]
            : 'Upcoming Event'
        }
        link={`/event/${generateSlug(event.title)}`}
      >
        {event.held_by ? `by ${event.held_by}` : null}
        {event.excerpt ? event.excerpt : null}
      </Card>
    </Container>
  );
};

export default HomepageEventsCETComponent;
