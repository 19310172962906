import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { device } from '../../constants';
import MDX from '../../cms/MDX';

const Container = styled.div`
  display: flex;
  background-color: ${props => props.theme.colors.purple};
`;

const QuoteContent = styled.div`
  & > * {
    font-size: 36px;
    &:before {
      content: '“';
      display: inline-block;
    }
    &:after {
      content: '”';
      display: inline-block;
    }
  }

  @media screen and ${device.mobileM} {
    & > * {
      font-size: 48px;
    }
  }
  @media screen and ${device.laptop} {
    & > * {
      font-size: 56px;
    }
  }
`;
const QuoteName = styled.p`
  font-size: 24px;
  text-align: right;
  margin-top: ${props => props.theme.paddings.pd}px;
`;
const QuoteContainer = styled.div`
  flex-basis: 100%;
  color: #fff;
  position: relative;
  z-index: 1;
  padding: ${props => props.theme.paddings.pd}px;

  @media screen and ${device.laptop} {
    padding: calc(${props => props.theme.paddings.pd}px * 2.5);
    flex-basis: 50%;
  }
`;

const SpeechBubbleImg = styled.img`
  position: absolute;
  top: calc(50% - 175px);
  left: calc(50% - 50px);
  height: 350px;
  z-index: -1;
  display: none;

  @media screen and ${device.laptop} {
    display: block;
  }
`;

interface FeaturedImgProps {
  bgImg: string;
}
const FeaturedImgContainer = styled.div<FeaturedImgProps>`
  display: none;
  background-image: url('${props =>
    props.bgImg ? props.bgImg : '/images/design/hero/resources.png'}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media screen and ${device.laptop} {
    flex-basis: 50%;
    display: block;
  }
`;

const HomepageTestimonialsComponent: React.FC = function () {
  const testimonialsResult = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "testimonial" } }) {
        edges {
          node {
            childMdx {
              frontmatter {
                name
                content
                featured_image
                information
              }
            }
          }
        }
      }
    }
  `);

  const { edges } = testimonialsResult?.allFile;
  const randIndex = Math.floor(Math.random() * edges.length);
  const selectedTestimonial = edges[randIndex];

  return (
    <Container>
      <QuoteContainer>
        <QuoteContent>
          <MDX>{selectedTestimonial.node.childMdx.frontmatter.content}</MDX>
        </QuoteContent>
        <QuoteName>
          - {selectedTestimonial.node.childMdx.frontmatter.name},{' '}
          {selectedTestimonial.node.childMdx.frontmatter.information}
        </QuoteName>
        <SpeechBubbleImg src="/images/design/speech-bubble.png" />
      </QuoteContainer>
      <FeaturedImgContainer
        bgImg={selectedTestimonial.node.childMdx.frontmatter.featured_image}
      />
    </Container>
  );
};

export default HomepageTestimonialsComponent;
