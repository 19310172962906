import React, { useContext } from 'react';
import styled from 'styled-components';
import HomePageEventsFGComponent from './focus-group';
import HomePageEventsCETComponent from './cet';
import LinkButton from '../../form-elements/LinkButton';
import ColouredPageSection from '../../ColouredPageSection';
import { device } from '../../../constants';
import SiteConfigContext from '../../../context/SiteConfigContext';
import EventsContext from '../../../context/EventsContext';

const HighlightsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: ${props => props.theme.paddings.pd}px;
  flex-direction: column;

  & + a button {
    width: auto;
  }

  @media screen and ${device.laptop} {
    flex-direction: row;
  }
`;

const HomepageEventsComponent: React.FC = function () {
  const siteCfg = useContext(SiteConfigContext);
  const events = useContext(EventsContext) ?? [];

  if (siteCfg == null) {
    return null;
  }

  const { lhs_top, lhs_bottom, rhs } = siteCfg?.homepage_featured_events;

  const lhs = [lhs_top, lhs_bottom];
  const sectionDescription =
    siteCfg?.homepage_events_section_description ??
    `Event calendar of upcoming programmes, and find out what’s happening in
  the community`;

  const lhsEvents = events.filter(e => lhs.indexOf(e.name) > -1);

  const rhsEvent = events.filter(e => [rhs].indexOf(e.name) > -1)[0];

  return (
    <ColouredPageSection
      title="Upcoming Programmes"
      sectionDescription={sectionDescription}
      colorStopValue="50"
    >
      <HighlightsContainer>
        {lhsEvents && <HomePageEventsFGComponent events={lhsEvents} />}
        {rhsEvent && <HomePageEventsCETComponent event={rhsEvent} />}
      </HighlightsContainer>
      <LinkButton
        link="/programmes"
        buttonText="View Full Programme Calendar"
        buttonType="outline"
        background="#FFF"
      />
    </ColouredPageSection>
  );
};

export default HomepageEventsComponent;
