import React from 'react';
import Layout from '../layouts/Layout';
import styled, { useTheme } from 'styled-components';

import SEO from '../components/SEO';
import LinkButton from '../components/form-elements/LinkButton';
import HomepageEventsComponent from '../components/homepage/events/events';
import HomepageAnnouncementsComponent from '../components/homepage/Announcements';
import HomepageTestimonialsComponent from '../components/homepage/Testimonials';
import SubscribeForm from '../components/forms/SubscribeForm';
import { device } from '../constants';
import { PageProps } from 'gatsby';

const MemberCTA = styled.section`
  padding: ${props => props.theme.paddings.pd}px;

  @media screen and ${device.laptop} {
    padding: calc(${props => props.theme.paddings.pd}px * 2.5);
  }

  background-color: ${props => props.theme.colors.orangeGray};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  @media screen and ${device.laptop} {
    flex-direction: row;
  }
`;

const MemberCTADescription = styled.p`
  font-family: Montserrat, sans-serif;
  font-size: 36px;
  line-height: 50px;
  flex: 1 1 70%;
  margin: ${props => props.theme.paddings.pd}px 0;
  text-align: center;

  @media screen and ${device.laptop} {
    text-align: left;
    margin: 0 ${props => props.theme.paddings.pd}px 0 0;
  }
`;

const Home: React.FC<PageProps> = function (props) {
  const { location } = props;
  const theme = useTheme();

  return (
    <Layout>
      {/* @TODO: Make configurable? */}
      <SEO
        title="Singapore Drama Educators Association"
        path={location?.pathname}
      />
      <HomepageTestimonialsComponent />
      <MemberCTA>
        <MemberCTADescription>
          Join us in advancing the profession of the drama/theatre educator,
          advocating for the practice and value of drama and theatre.
        </MemberCTADescription>
        <div>
          <LinkButton
            noLink={false}
            buttonText={'Be A Member →'}
            link={'/apply'}
            buttonType="outlineWhite"
            background={theme.colors.orangeGray}
          />
        </div>
      </MemberCTA>
      <HomepageAnnouncementsComponent />
      <HomepageEventsComponent />
      <SubscribeForm />
    </Layout>
  );
};

export default Home;
