import React, { useMemo, useState } from 'react';
import { Link } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import SectionDescription from '../formatting/SectionDescription';
import { device } from '../../constants';
import urljoin from 'url-join';

const Container = styled.section`
  padding: ${props => props.theme.paddings.pd}px;

  @media screen and ${device.laptop} {
    padding: calc(${props => props.theme.paddings.pd}px * 2.5);
  }
`;

const Title = styled.h2`
  margin-bottom: ${props => props.theme.paddings.halfpd}px;
  font-size: 24px;

  &:not(.underlined-heading):after {
    height: 0px;
  }
`;

const Excerpt = styled.p`
  font-family: 'Open Sans';
`;

const AnnouncementItem = styled.section`
  margin-bottom: calc(${props => props.theme.paddings.pd}px * 1.5);
  cursor: pointer;
`;

const AnnouncementsList = styled.div`
  margin-right: 0px;

  @media screen and ${device.laptop} {
    flex: 1 1 40%;
    margin-right: ${props => props.theme.paddings.pd}px;
  }
`;
const AnnouncementImagesContainer = styled.div`
  flex: 1 1 100%;
  max-height: 100%;
  margin-bottom: ${props => props.theme.paddings.pd}px;
  display: flex;
  overflow: hidden;
  position: relative;

  @media screen and ${device.laptop} {
    flex: 0 1 50%;
    max-height: calc(
      (100vw - (${props => props.theme.paddings.pd}px * 2.5 * 2)) / 2 -
        ${props => props.theme.paddings.pd}px
    );
    margin-bottom: 0;
  }
`;
interface FeaturedImgProps {
  bgImg: string;
  currentIndex: number;
}
const AnnouncementImageDiv = styled.div<FeaturedImgProps>`
  padding-bottom: 100%;
  background-image: url('${props =>
    props.bgImg ? props.bgImg : '/images/design/hero/resources.png'}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  flex: 1 0 100%;
  left: calc(-${props => (props.currentIndex ? props.currentIndex : 0)} * 100%);
`;

const AnnouncementCarousel = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap-reverse;
`;

const CarouselNavigation = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: calc(50% - ((3 * 35px) / 2));
`;

const CarouselNavigationDot = styled.div`
  cursor: pointer;
  width: 25px;
  height: 25px;
  background-color: #fff;
  border: 1px solid ${props => props.theme.colors.purple};
  border-radius: 50%;
  margin-left: 10px;

  &:first-child {
    margin-left: 0;
  }

  &.active {
    background-color: ${props => props.theme.colors.purple};
  }
`;

const HomepageAnnouncementsComponent: React.FC = function () {
  const announcementsResult = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "news" }
          childMdx: {
            frontmatter: { category: { eq: "Public Announcements" } }
          }
        }
        sort: { fields: childMdx___frontmatter___pub_date, order: DESC }
        limit: 3
      ) {
        edges {
          node {
            childMdx {
              frontmatter {
                title
                excerpt
                featured_image
                link
              }
            }
          }
        }
      }
    }
  `);

  const { edges } = announcementsResult?.allFile;
  const [currentFeaturedImgIndex, setCurrentFeaturedImgIndex] = useState(0);

  const entries = useMemo(
    () =>
      edges?.map((edge: any) => {
        return {
          ...edge.node.childMdx.frontmatter,
          link: urljoin('/news', edge.node.childMdx.frontmatter.link),
        };
      }),
    [edges]
  );

  return (
    <Container>
      <h1>Announcements</h1>
      <SectionDescription>
        Latest news, open calls, special workshops and other highlights from us
      </SectionDescription>

      <AnnouncementCarousel>
        <AnnouncementsList>
          {entries.map((entry: any, index: number) => {
            return (
              <AnnouncementItem
                key={index}
                onClick={() => setCurrentFeaturedImgIndex(index)}
              >
                <Title
                  className={
                    currentFeaturedImgIndex == index ? 'underlined-heading' : ''
                  }
                >
                  <Link to={entry.link}>{entry.title}</Link>
                </Title>
                <Excerpt>{entry.excerpt}</Excerpt>
              </AnnouncementItem>
            );
          })}
        </AnnouncementsList>
        <AnnouncementImagesContainer>
          {entries.map((entry: any, index: number) => {
            return (
              <AnnouncementImageDiv
                key={index}
                currentIndex={currentFeaturedImgIndex}
                bgImg={entry.featured_image}
              />
            );
          })}
          <CarouselNavigation>
            {entries.map((entry: any, index: number) => (
              <CarouselNavigationDot
                key={index}
                className={currentFeaturedImgIndex == index ? 'active' : ''}
                onClick={() => setCurrentFeaturedImgIndex(index)}
              />
            ))}
          </CarouselNavigation>
        </AnnouncementImagesContainer>
      </AnnouncementCarousel>
    </Container>
  );
};

export default HomepageAnnouncementsComponent;
